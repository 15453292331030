import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/ItSolutions.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "IT Support ",
    paragraph: "Remote IT support to keep your business running smoothly.",
  },
  {
    id: 2,
    src: TimeStres,
    title: "IT HelpDesk ",
    paragraph: "Get immediate assistance with technical problems.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Desktop Support",
    paragraph: "Resolve mechanical issues with hardware and software.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Network Support",
    paragraph: "Fix connection issues and increase productivity. ",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Business Continuity Services",
    paragraph: "Disaster recovery in the event of emergency.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Business Continuity Services",
    paragraph: "Disaster recovery in the event of emergency.",
  },
];

export const HeaderData = {
  src: Internet,
  subtitle: "Say Goodbye to Internet Stress",
  title: "IT Solutions",
  paragraph: "Leave technical issues to the experts with ongoing IT support",
  to: Product_Quote_Routes.ITSolutions,
};

export const DescriptionContent = {
  title: "Why Do You Need IT Solutions?",
  to: Product_Quote_Routes.ITSolutions,
  paragraph:
    "Effective IT solutions are an integral part of a successful business. Digital Comparison will match you with the right suppliers to troubleshoot technical problems and provide assistance with hardware, software, connectivity, and more. ",
  benifittitle: "Financially Focused Benefits",
  benifitheading: "How Your Business Will Profit?",
  profitPoint1: "Automated updates ",
  profitPoint2: "Continued IT support ",
  profitPoint3: "IT disaster recovery ",
  profitPoint4: "Reduced maintenance costs",
};

export const FAQS = [
  {
    title: "Why is IT support crucial for my business?",
    answer:
      "By using IT solutions, you’ll be free to concentrate on the bones of your business and let professionals deal with the digital side. ",
  },
  {
    title: "Why do I need a disaster recovery plan?",
    answer:
      "A disaster recovery plan will protect you from losing critical data, operations, and more in the case of technical issues or human error.",
  },
  {
    title: "What is virtualisation?",
    answer:
      "Virtualisation is the creation of a virtual (rather than physical) operating system, server, or other network resource. ",
  },
  {
    title: "How much do IT solutions cost?",
    answer:
      "IT solutions vary in price depending on your requirements. Fill out our questionnaire and we’ll find you the best internet prices according to your needs. ",
  },
  {
    title: "What is DaaS?",
    answer:
      "DaaS refers to desktop-as-a-service, which uses a Cloud-based service to deliver desktop services and virtual applications. ",
  },
  {
    title: "How do IT solutions save me money?",
    answer:
      "By using IT support, you can increase efficiency and reduce fees by focusing on your business rather than technical issues. ",
  },
  {
    title: "How can Digital Comparison get me IT support?",
    answer:
      "We connect you with the top suppliers of IT solutions in the country. Simply compare the offers until you find your ideal solution",
  },
  {
    title: "Which IT solutions provider is the best?",
    answer:
      "We only work with trusted industry leaders. Choose the right one for you by comparing the offers we organise for you.  ",
  },
];
